// 
// Initialize plugins and behaviors on document ready
// 
$(function () {
  atvImages.init();
  AOS.init({
    once: true
  });
  navbar.init();
  swiper.init();
  pricing.init();
  zoomerang.init();
  tooltip.init();
  elevateZoom.init();
  highlight.init();
  datepicker.init();
  smoothScroll.init();
  lazyLoading.init();
  $.fancybox.defaults.loop = true;
}); // 
// Add extra behavior to the navbar
// 

var navbar = {
  init: function init() {
    if (!window.utils.isMobile()) {
      this.initDropdownHover();
    } // prevent dropdown link click to hide the dropdown


    $('.navbar .dropdown-item').click(function (e) {
      e.stopPropagation();
    }); // toggle to show/hide dropdown submenus on click

    $('.dropdown-submenu .dropdown-toggle').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $parent = $(this).parent();
      $parent.find('> .dropdown-menu').toggleClass('show');
      $(this).attr("aria-expanded", function (index, attr) {
        return attr == 'true' ? 'false' : 'true';
      });
    }); // toggle a bg color to the mobile menu when transparent

    $('.navbar-collapse').on('show.bs.collapse', function () {
      $(this).closest('.navbar').addClass('navbar-toggled');
    });
    $('.navbar-collapse').on('hide.bs.collapse', function () {
      $(this).closest('.navbar').removeClass('navbar-toggled');
    });
  },
  // show/hide dropdown on hover
  initDropdownHover: function initDropdownHover() {
    var $dropdowns = $('.navbar .dropdown');
    $dropdowns.each(function (index, item) {
      var $item = $(this);
      $item.hover(function () {
        $item.find("> .dropdown-toggle").attr("aria-expanded", true);
        $item.find("> .dropdown-menu").addClass("show");
      }, function () {
        $item.find("> .dropdown-toggle").attr("aria-expanded", false);
        $item.find("> .dropdown-menu").removeClass("show");
      });
    });
  }
}; // 
// Initialize a zoomerang plugin via data-toggle="zoomerang"
// 

var zoomerang = {
  init: function init() {
    Zoomerang.config({
      maxHeight: 730,
      maxWidth: 900
    }).listen('[data-toggle="zoomerang"]');
  }
}; // 
// Initialize pricing tabs (monthly / yearly billing) behavior
// via data-toggle="switch-prices"
// Example can be seen at pricing-charts.html
// 

var pricing = {
  init: function init() {
    var $switcher = $('[data-toggle="switch-prices"]'),
        $prices = $(".switch-price");
    $switcher.change(function () {
      yearly = $(this).is(":checked");
      var period = yearly ? "switch-price-yearly" : "switch-price-monthly";
      $prices.removeClass("switch-price-active");
      $prices.filter("." + period + "").addClass("switch-price-active");
    });
  }
}; // 
// Initialize elevate zoom plugin via data-toggle="elevate-zoom"
// Example can be seen at ecommerce/product.html
// 

var elevateZoom = {
  init: function init() {
    if (!window.utils.isMobile()) {
      $("[data-toggle='elevate-zoom']").elevateZoom({
        zoomWindowFadeIn: 500,
        zoomWindowFadeOut: 500,
        lensFadeIn: 500,
        lensFadeOut: 500,
        border: 2
      });
    }
  }
}; //
// Initialize a Swiper instance via data-toggle="swiper"
// and passing the options object via data-options
//

var swiper = {
  init: function init() {
    $("[data-toggle='swiper']").each(function (index, item) {
      new Swiper(item, $(item).data('options'));
    });
  }
}; //
// Initialize Bootstrap tooltip plugins
//

var tooltip = {
  init: function init() {
    $('[data-toggle="tooltip"]').tooltip();
  }
}; //
// Initialize atvImg plugin
// Example can be seen at index.html
//

var atvImages = {
  init: function init() {
    atvImg();
  }
}; // 
// Code syntax highlighting
// used in the docs
// 

var highlight = {
  init: function init() {
    $('code.hl').each(function (i, block) {
      hljs.highlightBlock(block);
    });
  }
}; // 
// Initialize a pikaday datepicker
// via data-toggle="datepicker"
// 

var datepicker = {
  init: function init() {
    $('[data-toggle="datepicker"]').each(function (index, el) {
      new Pikaday({
        field: $(el)[0]
      });
    });
  }
}; // 
// Initialize smooth scrolling
// via data-toggle="scroll"
// 

var smoothScroll = {
  init: function init() {
    var scroll = new SmoothScroll('[data-toggle="scroll"]', {
      easing: 'easeOutCubic',
      speed: 600,
      speedAsDuration: true
    });
  }
}; // 
// Initialize lazy loading polyfill
//

var lazyLoading = {
  init: function init() {
    if ('loading' in HTMLImageElement.prototype) {
      var images = document.querySelectorAll("img.lazyload");
      images.forEach(function (img) {
        img.src = img.dataset.src;
      });
    } else {
      var script = document.createElement("script");
      script.async = true;
      script.src = "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.2.0/lazysizes.min.js";
      document.body.appendChild(script);
    }
  }
}; // 
// Extra Helpers
// 

window.utils = {
  isMobile: function isMobile() {
    if (window.innerWidth <= 992) {
      return true;
    } else {
      return false;
    }
  }
};
jQuery(document).ready(function () {
  // const swiper = new Swiper('#diacarePrograms .swiper-container', {
  //     // Default parameters
  //     slidesPerView: 1,
  //     navigation: { nextEl: ".swiper-custom-button-next2", prevEl: ".swiper-custom-button-prev2" },
  //     speed: 1000
  // })
  var controller = new ScrollMagic.Controller();

  if (jQuery("#patients-journey").length) {
    if ($(window).width() >= 1024) {
      var patients_j_width = jQuery("#patients-journey").get(0).scrollWidth;
      var offset = jQuery(window).innerHeight();
      var scene1 = new ScrollMagic.Scene({
        triggerElement: "#patients-journey",
        duration: patients_j_width,
        offset: offset / 2
      }).setPin("#patients-journey").addTo(controller);
      scene1.on("progress", function (e) {
        var scrollItem = jQuery(".scroll-item");
        var scrollWidth = scrollItem.innerWidth() * scrollItem.length;
        var offset = jQuery(window).innerHeight();
        var p = e.progress * offset * 4;
        console.log(offset);
        jQuery(".scroll-item").css("transform", "translateX(-" + p + "px)"); // console.log(p)
      });
    }
  }

  if ($(window).width() >= 1024) {
    var scene2 = new ScrollMagic.Scene({
      triggerElement: "#about-khatri",
      duration: 560,
      offset: 320
    }).setPin(".banner-khatri").addTo(controller);
    var p_program_d = jQuery(".p-program");
    var p_program = [];
    var programIcon = jQuery(".program-icon");
    p_program_d.each(function (e, obj) {
      jQuery(obj).attr("id", "p-program" + e);
      p_program[e] = new ScrollMagic.Scene({
        triggerElement: "#p-program" + e,
        duration: "100%"
      }).addTo(controller);
      p_program[e].on("enter", function (e) {
        var t = jQuery(obj).offset().top;
        var objPaddingLeft = jQuery(obj).css("padding-left");
        var r = jQuery(obj).offset().left + jQuery(obj).width() + parseInt(objPaddingLeft.replace("px", ""));
        programIcon.css("top", t + "px");
        programIcon.css("left", r + "px");
        programIcon.css("opacity", "1");
      });
    });
  }

  var body = document.querySelector("#diacarePrograms");
  var scroller = {
    target: document.querySelector(".swiper-wrapper2"),
    ease: .09,
    // <= scroll speed
    endX: 0,
    x: 0,
    resizeRequest: 1,
    scrollRequest: 0,
    start: 0
  };
  var requestId = null;
  TweenLite.set(scroller.target, {
    rotation: 0.01,
    force3D: true
  });

  if (jQuery(scroller.target.length)) {
    window.addEventListener("load", onLoad);
  }

  function onLoad() {
    window.focus();
    updateScroller(); // window.addEventListener("resize", onResize);
    // document.addEventListener("scroll", onScroll);
  }

  function updateScroller() {
    var resized = scroller.resizeRequest > 0;

    if (resized) {
      var width = scroller.target.clientWidth;
      body.style.width = width + "px";
      scroller.resizeRequest = 0;
    }

    var windowPageOffset = window.pageYOffset - jQuery("#diacarePrograms").innerWidth();
    var scrollY = windowPageOffset || 0;

    if (scroller.start == 1) {
      scroller.endX = scrollY;
      scroller.x += (scrollY - scroller.x) * scroller.ease;

      if (Math.abs(scrollY - scroller.x) < 0.05 || resized) {
        scroller.x = scrollY;
        scroller.scrollRequest = 0;
      }

      TweenLite.set(scroller.target, {
        x: -scroller.x
      });
      requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
    }
  }

  var diacareProgramsheight = jQuery("#diacarePrograms").innerHeight();
  var start = false;

  if (jQuery(window).innerWidth() >= 991) {
    var sliderInner = jQuery("#diacarePrograms .swiper-wrapper").innerWidth();
    var slider_width = jQuery("#diacarePrograms .swiper-wrapper").get(0).scrollWidth;
    var scrollWidth = slider_width - sliderInner;
    var scene3 = new ScrollMagic.Scene({
      triggerElement: "#diacarePrograms",
      duration: scrollWidth,
      offset: diacareProgramsheight / 2
    }).setPin("#diacarePrograms").addTo(controller);
    scene3.on("enter", function (e) {
      scroller.start = 1;
      updateScroller();
    });
    scene3.on("leave", function (e) {// scroller.start = 0
    });
    scene3.on("progress", function (e) {
      scroller.scrollRequest++;

      if (!requestId) {
        requestId = requestAnimationFrame(updateScroller);
      }
    });
  }
});
var xMousePos = 0;
var yMousePos = 0;
var lastScrolledLeft = 0;
var lastScrolledTop = 0;
var cursorX = 0;
var cursorY = 0;
$(document).mousemove(function (event) {
  captureMousePosition(event);
});
$(window).scroll(function (event) {
  if (lastScrolledLeft != $(document).scrollLeft()) {
    xMousePos -= lastScrolledLeft;
    lastScrolledLeft = $(document).scrollLeft();
    xMousePos += lastScrolledLeft;
  }

  if (lastScrolledTop != $(document).scrollTop()) {
    yMousePos -= lastScrolledTop;
    lastScrolledTop = $(document).scrollTop();
    yMousePos += lastScrolledTop;
  }

  window.status = "x = " + xMousePos + " y = " + yMousePos;
  cursorX = xMousePos;
  cursorY = yMousePos;
});

function captureMousePosition(event) {
  xMousePos = event.pageX;
  yMousePos = event.pageY;
  window.status = "x = " + xMousePos + " y = " + yMousePos;
  cursorX = xMousePos;
  cursorY = yMousePos;
}

jQuery(".slider-i").on("mouseenter", function () {
  jQuery(".cursor-slider").addClass("slider-enterd");
});
jQuery(".slider-i").on("mouseleave", function () {
  jQuery(".cursor-slider").removeClass("slider-enterd");
});
animateCursor();

function animateCursor() {
  jQuery(".cursor-slider").css("top", cursorY - 50 + "px");
  jQuery(".cursor-slider").css("left", cursorX - 50 + "px");
  requestAnimationFrame(animateCursor);
}

jQuery(".video-i").on("mouseenter", function (e) {
  jQuery(".video-i .play-btn").addClass("active");
  jQuery(".video-i .play-btn").removeClass("leaved");
});
jQuery(".video-i").on("mouseleave", function (e) {
  jQuery(".video-i .play-btn").removeClass("active");
  jQuery(".video-i .play-btn").addClass("leaved");
  jQuery(".video-i .play-btn").css("left", "");
  jQuery(".video-i .play-btn").css("top", "");
});
jQuery(".video-i").on("mousemove", function (e) {
  var videoX = jQuery(this).offset().left;
  var videoY = jQuery(this).offset().top;
  var mouceX = cursorX - videoX;
  var mouceY = cursorY - videoY;
  var playBtn = jQuery(this).find(".play-btn.active");
  playBtn.css("left", mouceX + "px");
  playBtn.css("top", mouceY + "px");
});
jQuery(document).ready(function () {
  jQuery("*[tabOpen]").on("mouseenter", function () {
    var target = jQuery(this).attr("tabTarget");
    jQuery(".content-tab").removeClass("active");
    jQuery("" + target + "").addClass("active");
    jQuery("*[tabOpen]").removeClass("active");
    jQuery(this).addClass("active");
  });
});
jQuery(window).on("scroll", function (e) {
  var scroll = jQuery(window).scrollTop();

  if (scroll >= 50) {
    jQuery("nav.navbar").addClass("shadow-sm2");
  } else {
    jQuery("nav.navbar").removeClass("shadow-sm2");
  }
});

function scrollDetect() {
  var lastScroll = 0;

  window.onscroll = function () {
    var currentScroll = document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value

    if (currentScroll > 0 && lastScroll <= currentScroll) {
      lastScroll = currentScroll;
      document.querySelector("html").classList.add("down");
      document.querySelector("html").classList.remove("up");
    } else {
      lastScroll = currentScroll;
      document.querySelector("html").classList.add("up");
      document.querySelector("html").classList.remove("down");
    }
  };
}

scrollDetect();
jQuery(".navbar-toggler").click(function () {
  jQuery(".manu").toggleClass("active");
}); // animation

jQuery(document).ready(function () {
  var split = new SplitText(jQuery('.animate-title'), {
    type: "lines,words",
    linesClass: "line",
    wordsClass: "word"
  });
  jQuery(".video-play").click(function () {
    var id = jQuery(this).attr("data-model");
    jQuery("#" + id).addClass("open");
  });
  jQuery(".video-btn").click(function () {
    var id = jQuery(this).parents(".video-model").removeClass('open');
    var video = jQuery("#pvideo").attr("src");
    jQuery("#pvideo").attr("src", "");
    jQuery("#pvideo").attr("src", video);
  });
});
jQuery(window).on("load", function () {
  jQuery("html").addClass("is-loaded");
});

function toggleAppointment() {
  jQuery(".book-appointment").toggleClass("open");
}

jQuery("input[type='tel']").on("keydown", function (e) {
  var keyCode = e.originalEvent["code"];
  var iskey = keyCode.search("Key");

  if (iskey != -1) {
    e.preventDefault();
  }
});
jQuery("form").submit(function (e) {
  e.preventDefault();
  var ths = jQuery(this);
  var formdata = jQuery(this).serializeArray();
  request = $.ajax({
    url: "./send-message.php",
    type: "post",
    data: formdata
  }); // Callback handler that will be called on success

  request.done(function (response, textStatus, jqXHR) {
    // Log a message to the console
    var data = JSON.parse(response);
    var noticeTag = ths.find(".notice");

    if (data["status"] == 1) {
      ths.trigger("reset");
      noticeTag.html('<div class="title-3">Thank you for connecting with us</div><p class="p mt-3">We\'ll get back to you at the earliest.</p>');
      ths.addClass("success");
      setTimeout(function () {
        ths.removeClass("success");
        ths.addClass("success-removed");
      }, 5000);
      setTimeout(function () {
        ths.removeClass("success-removed");
      }, 6000);
    } else {
      ths.addClass("failed");
      setTimeout(function () {
        ths.removeClass("failed");
      }, 3000);
    }
  }); // Callback handler that will be called on failure

  request.fail(function (jqXHR, textStatus, errorThrown) {
    // Log the error to the console
    console.error("The following error occurred: " + textStatus, errorThrown);
  });
});